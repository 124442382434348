<template>

  <!-- Card -->

  <b-row class="hr">
    <b-col
      cols="12"
      lg="9"
    >
      <b-card>
        <b-card-title>
          Workplace Safety
        </b-card-title>
        <h4><strong class="">Overview</strong></h4>
        <p>
          The purpose of this section is to provide a base understanding in the difference between hazard and risk, and how to recognize hazards in the home; a basic
          knowledge of fire safety, fall prevention, personal safety, and WHMIS; and to increase understanding and increase knowledge safety in safe lifting techniques and infection control.
          <strong>
            <a
              :href="`${mainUrl}files/NewHireOrientation.pdf`"
              target="_blank"
            >
              SEE MORE.
            </a>
          </strong>
        </p>
        <br>
        <h4><strong class="">Objectives</strong></h4>
        <ul>
          <li>
            Emphasize and increase knowledge of the proper body mechanics in lifting and moving heavy objects;
          </li>
          <li>
            Become familiar with the different types of fire hazards;
          </li>
          <li>
            Understanding the different types of fire extinguishers and how to use them;
          </li>
          <li>
            Understanding proper wasted disposal and how to recognize the first signs of Infection
          </li>
          <li>
            Learn how to prvent falls in the workplace
          </li>
          <li>
            Understand WHMIS
          </li>
          <li>
            Understand Fire Safety
          </li>
          <li>
            Understand some Personal Safety Techniques
          </li>
        </ul>
        <br>
        <h4><strong class="">Safety Mandate</strong></h4>
        <ol>
          <li>
            To create a safe working environment
          </li>
          <li>
            To view all accidents as being preventable
          </li>
          <li>
            To provide preventive information
          </li>
          <li>
            To listen and act on all safety concerns
          </li>
          <li>
            For employees to be responsible for identifying workplace hazards
          </li>
        </ol>
        <br>
        <h4><strong class="">Potential Hazards to Home Care Workers</strong></h4>
        <ul>
          <li>
            Potential violence from clients or others
          </li>
          <li>
            Exposure to communicable disease
          </li>
          <li>
            Ergonomic issues(lifting clients, etc)
          </li>
          <li>
            Physical conditions (ppor lifting techniques, temperature extremes, broken stairs, snow/ice on walkways, etc)
          </li>
          <li>
            Hazardous chemicals
          </li>
          <li>
            Second Hand smoke
          </li>
          <li>
            Pets
          </li>
          <li>
            Oxygen Equipemnts
          </li>
          <li>
            Fatigue
          </li>
        </ul>
        <br>
      </b-card>
    </b-col>

    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>
        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          disabled
          @click="$store.dispatch('app-hr18/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          class="mb-75"
          block
          disabled
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form17b', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form19', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol, VBToggle, BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ref } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,

    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const userData = ref(getUserData())

    return {
      formsOptions,
      userData,
    }
  },
  data() {
    return {
      id: this.$route.query.id,
      action: this.$route.params.action,

      forms: this.$route.name,

      mainUrl: this.$mainUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style >
.input-label> label {
    height: 40px;
}
.hr-break{
  border-bottom:1px solid lightgray;
  margin-bottom:10px;
}
.feather-icon >.feather-check-circle{
  color: green!important;
  width: 18px;
}
.feather-icon >.feather-x-circle{
  color: red!important;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
